import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message, Breadcrumb, Select, TextArea, Modal, Confirm } from 'semantic-ui-react'
import { withRouter, Link } from "react-router-dom";
import ajax from '../../../commons/agent.js'
import utility from '../../../commons/utility.js'
import util from './CreateDcn.js';
import DatePicker from "react-datepicker";
import DisplayMessage from '../../../commons/displayMessage.jsx';
import FileUpload from '../../../commons/fileupload/fileUpload.jsx';
import PageHeader from '../../PageHeader.jsx';
import { withTranslation } from "react-i18next";
// import PdfPreview from '../../../commons/PdfPreview.jsx';
import PdfPreview from '../../../commons/fileupload/PdfPreview.jsx';
import AddressLookUpMap from '../../../commons/geomap/AddressLookUpMap.jsx';
import AddressLookUp from '../AddressLookUp.jsx';
import BreedImage from '../../../commons/fileupload/BreedImage.jsx';
import { message as floatNoti } from 'antd'
import FileUploadNew from '../../../commons/fileupload/FileUploadNew.jsx';

const defFormData = {
   dcnId: null,
   parentDcnId: null,
   lastDcnId: null,
   dcnStatus: null,
   bpid: null,
   dogNeutered: null,
   remark: null,
   createdAt: null,
   updatedAt: null,
   createdBy: null,
   updatedBy: null,

   referenceNo: '',
   personForename: '',
   personSurname: '',
   personDob: '',
   personAddressLine1: '',
   personAddressLine2: '',
   personAddressLine3: '',
   personCity: '',
   personPostCode: '',
   personUprn: '',
   dogName: '',
   dogMicrochipCode: '',
   dogBreed: 'Other',
   dogSex: 'Male',
   dCNReason: '',
   dogIdentificationMark: '',
   issuer: 'Other',
   issuerForename: '',
   issuerSurname: '',
   issuerContactNumber: '',
   dateServed: '',
   issuerAddressLine1: '',
   issuerAddressLine2: '',
   issuerCity: '',
   issuerPostCode: '',
   breedImgLocation: 'unknown dog.jpg',

   dcnActionItems: [],
   dcnActionItem: { actionItemsId: null, actionName: '', actionStatus: 'Draft' },

   dcnFileName: '',
   sonFileName: '',
   tempDcnFileName: '',
   tempSonFileName: '',

   selectedMapObj: {},
   hasLookedUpAddress: false,
   disableSubmit: false,

}

export class CreateDcn extends Component {
   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],
         error: {},

         draftList: [],
         issuerNameOptions: [],
         dogBreedOptions: [],
         templateUploaded: [],

         ...defFormData,

         dogSexOptions: [
            { key: 'Male', value: 'Male', text: 'Male' },
            { key: 'Female', value: 'Female', text: 'Female' },
         ],
      };
   }

   componentDidMount() {
      this.props.fetchOrganizationCategory(utility.fetchUserBpid()) !== 'Local Authority' && this.props.history.push('/')
      this.onLoad()
      util.initialize(this, this.props.t)

      // let autoSaveIntervalId = setInterval(this.autoSave, 10000)
      let autoSaveIntervalId = setInterval(this.autoSave, 60000)
      this.setState({ autoSaveIntervalId: autoSaveIntervalId })
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.formData !== this.props.formData) {
         this.onLoad()
      }
   }
   componentWillUnmount() {
      clearInterval(this.state.autoSaveIntervalId)
      floatNoti.destroy()
   }

   autoSave = () => {
      if (!util.validateDraft()) {
         util.createDraft()
      }
   }

   downloadTemplate = () => {
      let self = this
      let filename = 'DCN Migration Template.xlsx'
      if (filename) {
         var promise = new Promise((resolve, reject) => {
            ajax.DCNService.downloadTemplateFile(filename).end(async (err, response) => {
               let errJson = utility.handleErrors(err);
               if (!err && response) {
                  let msg = [];
                  resolve(response);
               } else {
                  utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
               }
            })
         }).then((resolve) => {
            var headers = resolve.headers;
            var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            link.addEventListener("focus", w => { window.URL.revokeObjectURL(blob) });

         }).then((reject) => {
            //utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
         }).finally(() => {
         })
      }
   }

   uploadDcnExcel(e) {
      let self = this;
      if (self.state.templateUploaded != null) {
         let formData = new FormData()
         formData.append("file", self.state.templateUploaded[0])
         self.setState({ uploadingTemplate: true });
         ajax.DCNService.uploadDcn(formData).end((err, response) => {
            self.setState({ uploadingTemplate: false });
            let errJson = utility.handleErrors(err);
            if (!err && response) {
               let msg = [];
               if (response.status == "200") {
                  self.setState({ templateUploaded: [], });
                  let count = response.body.length
                  utility.loadDisplayMessage(self, "success", "Success", [`${count} draft(s) created`])
                  this.fetchDraftDcns()
               } else {
                  msg.push(response.text);
                  utility.loadDisplayMessage(self, "info", "Information.", msg);
               }
            } else {
               utility.loadDisplayMessage(self, "error", "Something went wrong.", [])
            }
         })
      }
   }


   onLoad = () => {
      const orgList = this.props.fetchOrganizationList()
      this.setState({ orgList: orgList })

      this.setDefaulDetails()
      if (this.props.formData) {
         this.initializeTheForm(this.props.formData)
         this.fetchDraftDcns()
      }
      else {
         this.addStep()
         this.fetchDraftDcns()
      }
   }

   initializeTheForm = (data) => {
      this.setState({
         dcnId: data.dcnId,
         dcnStatus: data.dcnStatus,
         bpid: data.bpid,
         parentDcnId: data.parentDcnId,
         lastDcnId: data.lastDcnId,

         bpid: data.bpid,
         referenceNo: data.dcnReferenceNo || '',
         dateServed: data.dcnEffectiveDate && new Date(data.dcnEffectiveDate),
         effectiveDate: data.effectiveDate,

         dogMicrochipCode: data.dogMicrochipCode || '',
         dogBreed: data.dogBreed || 'Other',
         dogName: data.dogName || '',
         dogSex: data.dogSex || 'Male',
         dCNReason: data.dcnReason || '',
         dogIdentificationMark: data.dogIdentificationMark || '',
         breedImgLocation: data.dogImgLocation || 'unknown dog.jpg',

         issuer: data.issuerUserId || 'Other',
         organizationName: data.localCouncilName || '',
         issuerForename: data.issuerForename || '',
         issuerSurname: data.issuerSurname || '',
         issuerAddressLine1: data.issuerAddressLine1 || '',
         issuerAddressLine2: data.issuerAddressLine2 || '',
         issuerCity: data.issuerCity || '',
         issuerPostCode: data.issuerPostCode || '',
         issuerContactNumber: data.issuerContactNo || '',

         personForename: data.ppForename || '',
         personSurname: data.ppSurname || '',
         personDob: data.ppDob && new Date(data.ppDob),
         personAddressLine1: data.ppAddressLine1 || '',
         personAddressLine2: data.ppAddressLine2 || '',
         personAddressLine3: data.ppAddressLine3 || '',
         personCity: data.ppCity || '',
         personPostCode: data.ppPostCode || '',
         personUprn: data.ppUprn || '',
         xCoordinate: data.xCoordinate,
         yCoordinate: data.yCoordinate,
         dcnActionItems: data.dcnActionItemsVo || [],

         dcnFileName: data.dcnFileName,
         sonFileName: data.sonFileName,
         tempDcnFileName: data.tempDcnFileName,
         tempSonFileName: data.tempSonFileName,


      })
   }

   reserForm = () => {
      this.addressLookUpReset()
      this.setState({ ...defFormData }, () => this.setDefaulDetails())
   }

   setDefaulDetails = () => {
      this.setState({ isFetchingMyOrg: true, })
      ajax.OrganizationMaster.fetchOrganizationByBpId(utility.fetchUserBpid()).end((err, res) => {
         this.setState({ isFetchingMyOrg: false })
         if (!err && res) {
            let issuerObj = res.body.organizationUsersVo.filter(item => item.userId === utility.fetchUserId())[0]
            let issuerNameOptions = res.body.organizationUsersVo.map(item => ({ key: item.userId, text: `${item.firstName} ${item.lastName}`, value: item.userId }))

            this.setState(
               {
                  myOrg: res.body,
                  myOrgUsers: res.body.organizationUsersVo,
                  // issuer: issuerObj ? `${issuerObj.firstName} ${issuerObj.lastName}` : 'Other',
                  issuerNameOptions: [...issuerNameOptions, { key: 'Other', text: 'Other', value: 'Other' }]
               },
               () => (issuerObj && !this.props.formData) && this.handleIssuerChange(issuerObj.userId)
            )
         }
         else {
            utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
         }
      })

      this.setState({ isFetchingDogBreed: true, })
      ajax.DCNService.getAllDogBreed().end((err, res) => {
         this.setState({ isFetchingDogBreed: false })
         if (!err && res) {
            this.setState({ dogBreedMaster: res.body, dogBreedOptions: res.body.map(item => ({ key: item.breedName, text: item.breedName, value: item.breedName })) })
         }
         else {
            utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
         }
      })
   }

   fetchDraftDcns = () => {
      this.setState({ isFetchingDraftDcns: true, })
      ajax.DCNService.fetchDraftDcns().end((err, res) => {
         this.setState({ isFetchingDraftDcns: false })
         if (!err && res) {
            this.setState({ draftList: res.body })
         }
         else {
            utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
         }
      })
   }

   addStep = () => {
      this.setState({ dcnActionItems: [...this.state.dcnActionItems, { ...this.state.dcnActionItem }] })
   }

   removeStep = (key) => {
      let dcnActionItems = [...this.state.dcnActionItems.filter((item, k) => k !== key)]
      this.setState({ dcnActionItems: [...dcnActionItems] })
   }

   handleTextChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
   }
   handleRadioChange = (name, value) => {
      this.setState({ [name]: value })
   }
   handleDropdownChange = (name, value) => {
      this.setState({ [name]: value })
   }

   handleDogBreedChange = (value) => {
      let obj = this.state.dogBreedMaster.filter(item => item.breedName === value)[0]
      this.setState({ dogBreed: value, breedImgLocation: obj ? obj.breedImgLocation : 'unknown dog.jpg' })
   }

   handleIssuerChange = (value) => {
      let myOrg = { ...this.state.myOrg }
      let obj = this.state.myOrgUsers.filter(item => item.userId === value)[0]
      if (obj) {
         this.setState({ issuer: obj.userId, issuerForename: obj.firstName, issuerSurname: obj.lastName, issuerContactNumber: obj.contactNumber, issuerAddressLine1: myOrg.addressLine1, issuerAddressLine2: myOrg.addressLine1 || '', issuerCity: myOrg.city, issuerPostCode: myOrg.zipCode })
      }
      else {
         this.setState({ issuer: 'Other', issuerForename: '', issuerSurname: '', issuerContactNumber: '', issuerAddressLine1: '', issuerAddressLine2: '', issuerCity: '', issuerPostCode: '' })
      }
   }

   handleCategoryChange = (name, value) => {
      let subCategoryOptions = []
      let catOptions = this.state.fetchedCategoryObj.filter(item => item.categoryCode === value)[0]
      catOptions && catOptions.subcategory.map(item2 => subCategoryOptions = [...subCategoryOptions, { key: item2.subcategoryCode, text: item2.subcategoryName, value: item2.subcategoryCode }])
      this.setState({ [name]: value, subCategoryOptions, subCategoryCode: null })
   }

   handleAttributeChange = (attributeCode) => {
      this.state.bussinessMetaData.map(item => item.attributes.map(item2 => {
         if (item2.attributeCode === attributeCode) item2.checked = !item2.checked
      }))
   }

   handleActionItemTextChange = (e, key) => {
      let dcnActionItems = [...this.state.dcnActionItems]
      dcnActionItems[key].actionName = e.target.value
      this.setState({ dcnActionItems: [...dcnActionItems] })
   }

   handleDateChange = (date, key) => {
      let dcnActionItems = [...this.state.dcnActionItems]
      dcnActionItems[key].dueDate = date
      this.setState({ dcnActionItems: [...dcnActionItems] })
   }

   deleteDraftDcn = () => {
      let self = this
      self.setState({ isDeletingDraft: true })
      ajax.DCNService.deleteDraftDcn(self.state.deleteDraftModal).end((err, response) => {
         self.setState({ deleteDraftModal: false, isDeletingDraft: false })
         if (!err && response) {
            self.fetchDraftDcns()
            self.reserForm()
         }
         else {
            utility.loadDisplayMessage(self, "error", "Something went wrong.", [])
         }
      });
   }
   deleteAllDraftDcn = () => {
      let self = this
      self.setState({ isDeletingAllDraft: true })
      ajax.DCNService.deleteAllDraftDcn().end((err, response) => {
         self.setState({ deleteAllDraftModal: false, isDeletingAllDraft: false })
         if (!err && response) {
            self.fetchDraftDcns()
            self.reserForm()
         }
         else {
            utility.loadDisplayMessage(self, "error", "Something went wrong.", [])
         }
      });
   }

   onFileUploadSuccess = () => {
      var self = this
      self.fetchDraftDcns()

      self.setState({ isFetching: true })
      ajax.DCNService.findDcnDetailsByDcnId(this.state.dcnId).end((err, response) => {
         self.setState({ isFetching: false })
         if (!err && response) {
            this.setState({
               dcnFileName: response.body.dcnFileName,
               sonFileName: response.body.sonFileName,
            })
         }
         else {
            utility.loadDisplayMessage(self, "error", "Something went wrong.", [])
         }
      });
   }

   downloadFile = (dcnId, filename) => {
      let self = this
      if (dcnId && filename) {
         var promise = new Promise((resolve, reject) => {
            ajax.DCNService.downloadFile(dcnId, filename).end(async (err, response) => {
               let errJson = utility.handleErrors(err);
               if (!err && response) {
                  let msg = [];
                  resolve(response);
               } else {
                  utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
               }
            })
         }).then((resolve) => {
            var headers = resolve.headers;
            var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            link.addEventListener("focus", w => { window.URL.revokeObjectURL(blob) });

         }).then((reject) => {
            //utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
         }).finally(() => {
         })
      }
   }
   deleteFile = () => {
      let self = this
      let { dcnId, fileName, type } = this.state.deleteFileConfirmation
      if (dcnId && fileName) {
         self.setState({ isDeletingFile: true })
         ajax.DCNService.deleteFile(dcnId, fileName, type).end((err, response) => {
            self.setState({ deleteFileConfirmation: false, isDeletingFile: false })
            let errJson = utility.handleErrors(err);
            if (!err && response) {
               this.onFileUploadSuccess()
            } else {
               utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
            }
         })
      }
   }

   addressLookUp = (e) => {
      e.preventDefault()
      let { lookupAddressText, lookupAddressUprn } = this.state
      let laCode = this.state.orgList.filter(org => org.organizationBpid === this.state.myOrg.organizationBpid)[0].laCode
      let query = {
         "dataset": null,
         "attribute": []
      }
      if (lookupAddressText) query.attribute = [...query.attribute, { name: 'ADDRESS_STRING', matchtype: 'contains', value: [lookupAddressText] }]
      if (lookupAddressUprn) query.attribute = [...query.attribute, { name: 'UPRN', value: [lookupAddressUprn] }]
      if (laCode) query.attribute = [...query.attribute, { name: 'LA_CODE', matchtype: 'equal to', value: [laCode] }]

      let Vo = { query }
      if (!lookupAddressText && !lookupAddressUprn) {
         utility.loadDisplayMessage(this, "warning", "Empty Address", ['Fill something in the address fields to look up.'])
      }
      else {
         this.setState({ isLookingUpAddress: true, })
         ajax.DCNService.addressLookUp(Vo).end((err, res) => {
            this.setState({ isLookingUpAddress: false, hasLookedUpAddress: true })
            if (!err && res) {
               let dataArr = res.body.SearchResponseMessage.NGSearchResponseData.Result.any.map(item => ({ ...item, x: item.X_COORD, y: item.Y_COORD, status: 'Active' }))
               let selectedMapObj = this.state.selectedMapObj && dataArr.filter(item => item.UPRN == this.state.selectedMapObj.UPRN)
               this.setState({
                  addressLookUpList: [...dataArr],
                  // selectedMapObj: selectedMapObj && selectedMapObj.length > 0 ? selectedMapObj[0] : {},
               })
            }
            else {
               utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
            }
         })
      }

      // let res = {
      //    "SearchResponseMessage": {
      //       "NGSearchResponseData": {
      //          "Header": {
      //             "ResultCount": 22,
      //             "ReturnCount": 22,
      //             "ErrorCode": 0,
      //             "ErrorMessage": "Success"
      //          },
      //          "Result": {
      //             "any": [
      //                {
      //                   "UPRN": 35000001,
      //                   "Y_COORD": 693618,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "1",
      //                   "X_COORD": 287561,
      //                   "ADDRESS_STRING": "1 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 733618800000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000002,
      //                   "Y_COORD": 693614,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "2",
      //                   "X_COORD": 287521,
      //                   "ADDRESS_STRING": "2 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000004,
      //                   "Y_COORD": 693610,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "4",
      //                   "X_COORD": 287517,
      //                   "ADDRESS_STRING": "4 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000005,
      //                   "Y_COORD": 693564,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "5",
      //                   "X_COORD": 287527,
      //                   "ADDRESS_STRING": "5 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000006,
      //                   "Y_COORD": 693604,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "6",
      //                   "X_COORD": 287511,
      //                   "ADDRESS_STRING": "6 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000007,
      //                   "Y_COORD": 693562,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "7",
      //                   "X_COORD": 287525,
      //                   "ADDRESS_STRING": "7 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000009,
      //                   "Y_COORD": 693552,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "9",
      //                   "X_COORD": 287519,
      //                   "ADDRESS_STRING": "9 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                }

      //             ]
      //          }
      //       }
      //    }
      // }

      // let d = res.SearchResponseMessage.NGSearchResponseData.Result.any.map(item => ({ ...item, x: item.X_COORD, y: item.Y_COORD, status: 'Active' }))
      // this.setState({
      //    addressLookUpList: d,
      //    selectedMapObj: this.state.selectedMapObj && d.filter(item => item.UPRN == this.state.selectedMapObj.UPRN),
      //    hasLookedUpAddress: true
      // })
   }
   addressLookUpReset = () => {
      this.setState({
         addressLookUpList: [],
         selectedMapObj: null,
         lookupAddressText: '',
         lookupAddressUprn: '',

         personAddressLine1: '',
         personAddressLine2: '',
         personAddressLine3: '',
         personCity: '',
         personPostCode: '',
         personUprn: '',
         xCoordinate: '',
         yCoordinate: '',
         selectedMapObj: '',
      })
   }
   handleAddressSelect = (data) => {
      this.setState({
         personAddressLine1: data.ADD_LINE_1 || '',
         personAddressLine2: data.ADD_LINE_2 || data.ADD_LINE_3 || '',
         personAddressLine3: data.ADD_LINE_2 ? data.ADD_LINE_3 || '' : '',
         personCity: data.TOWN || '',
         personPostCode: data.POST_CODE || '',
         personUprn: data.UPRN || '',
         xCoordinate: data.X_COORD || '',
         yCoordinate: data.Y_COORD || '',
         selectedMapObj: data,
      })
   }
   render() {
      const { activeIndex } = this.state
      const formMode = utility.resolveDcnStatus(this.state.parentDcnId, this.state.dcnStatus)

      return (
         <>
            {!formMode && <PageHeader />}
            {formMode === 'Variation' && <PageHeader customPath={`/dashboard/dogControlNotices/createVariation`} />}
            {formMode === 'Draft' && <PageHeader customPath={`/dashboard/dogControlNotices/draftDcn`} />}

            <Segment basic className='px-1 pt-0 mt-0'>
               <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />

               <Segment className='' loading={this.state.isFetchingDraftDcns} padded={this.state.isFetchingDraftDcns} >
                  <div className='textNormal textBold colPrimary mb-5px'>
                     Saved Drafts <Label basic horizontal className='colPrimary'>{this.state.draftList.length}</Label>
                     <Icon className='mr-0 cursorPointer' name='trash' color='red' onClick={(e) => { e.stopPropagation(); this.setState({ deleteAllDraftModal: true }) }} />
                  </div>
                  <div className="overflow-auto flex py-5px">
                     {this.state.draftList && this.state.draftList.map(item => {
                        return (
                           <Label basic style={{ minWidth: '168px' }} className={`fCard ${item.dcnId === this.state.dcnId && 'active'}`} onClick={() => this.initializeTheForm(item)}>
                              {/* <BreedImage imageName={`${item.dogImgLocation}`} style={{ maxHeight: '120px' }} /> */}

                              <div className='textNormal colPrimary'>
                                 <span>{utility.elipses(item.dcnReferenceNo, 18)} </span>
                                 <Icon className='mr-0' name='trash' color='red' onClick={(e) => { e.stopPropagation(); this.setState({ deleteDraftModal: item.dcnId }) }} />
                              </div>
                              <div className='textSmall textThin colGrey20'>{utility.resolveDcnStatus(item.parentDcnId, item.dcnStatus)}</div>
                              <div className='textSmall textThin colGrey60'>{utility.formatDate(item.updatedAt || item.createdAt)}</div>
                           </Label>
                        )
                     })}
                  </div>
               </Segment>

               {/* {!formMode && <> */}
               <Segment className='' >
                  <Grid columns='equal' centered className="mx-0">
                     <Grid.Column width={8} className='px-0'>
                        <div className='textNormal textBold colPrimary mb-1'>Upload Template</div>
                     </Grid.Column>
                     <Grid.Column width={8} className='px-0' textAlign='right'>
                        <span style={{ display: 'inline-block' }} className=''>Click <Icon className='border-rad colMoney mr-0 cursorPointer' name='file excel' size='large' onClick={() => this.downloadTemplate()} /> to download the template</span>
                     </Grid.Column>
                     <Grid.Column computer={12} tablet={16} className='py-0'>
                        <FileUploadNew
                           context={this}
                           name='templateUploaded'
                           title=''
                           fileTypesAllowedRegex={/(\.xlsx)$/i}
                           fileTypesAllowed={' .xlsx '}
                           maxFilesAllowed={1}
                           maxFileSize={2}
                        />
                        <Button disabled={!(this.state.templateUploaded.length > 0)} floated='right' className='customBtn1Primary mt-1 mb-1' size='small' onClick={() => this.uploadDcnExcel()} loading={this.state.uploadingTemplate} > Upload </Button>
                     </Grid.Column>
                  </Grid>
               </Segment>
               {/* </>} */}


               <Grid columns='equal'>
                  <Grid.Column >
                     <Segment>
                        <Form size='small'>
                           <Form.Group widths='equal'>
                              <Form.Field>
                                 <Form.Input readOnly={formMode === 'Variation'} name='referenceNo' label='Reference No' placeholder='Reference No (max 255 chars)' value={this.state.referenceNo} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateReferenceNo()} fluid required />
                                 {this.state.error.referenceNo && <div className='errorText'>{this.state.error.referenceNo}</div>}
                              </Form.Field>
                              <Form.Field required>
                                 <label>Date Served</label>
                                 <div className="ui left icon input">
                                    <DatePicker
                                       id='dateServed' placeholderText={`DD/MM/YYYY`} selected={this.state.dateServed || null}
                                       onChange={(date) => this.setState({ dateServed: date }, util.validateDateServed)}
                                       dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                       onBlur={() => util.validateDateServed()}
                                       isClearable
                                       strictParsing
                                    // minDate={new Date()}
                                    />
                                    <i aria-hidden="true" className="calendar outline icon"></i>
                                 </div>
                                 {this.state.error.dateServed && <div className='errorText'>{this.state.error.dateServed}</div>}
                              </Form.Field>
                           </Form.Group>
                        </Form>
                     </Segment>
                  </Grid.Column>
                  <Grid.Column stretched>
                     <Segment>
                        <Header as='h4' className='colPrimary'>Local council</Header>
                        <div className='textBig'>{this.state.myOrg && this.state.myOrg.organizationName}</div>
                     </Segment>
                  </Grid.Column>
               </Grid>

               <Segment>
                  <Header as='h4' className='colPrimary'>Details of the person to whom notice applies</Header>
                  <Grid columns='equal'>
                     <Grid.Column computer={8} tablet={16} mobile={16}>
                        <Form size='small'>
                           <Form.Group widths='equal'>
                              <Form.Field required>
                                 <label>Proper Person's Forename</label>
                                 <Input name='personForename' placeholder="Proper Person's Forename (max 50 chars)" value={this.state.personForename} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonForename()} fluid required />
                                 {this.state.error.personForename && <div className='errorText'>{this.state.error.personForename}</div>}
                              </Form.Field>
                              <Form.Field required>
                                 <label>Proper Person's Surname</label>
                                 <Input name='personSurname' placeholder="Proper Person's Surname (max 50 chars)" value={this.state.personSurname} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonSurname()} fluid required />
                                 {this.state.error.personSurname && <div className='errorText'>{this.state.error.personSurname}</div>}
                              </Form.Field>
                           </Form.Group>

                           <Form.Field>
                              <label>Date of birth</label>
                              <div className="ui left icon input">
                                 <DatePicker
                                    id='personDob' placeholderText={`DD/MM/YYYY`} selected={this.state.personDob || null}
                                    onChange={(date) => this.setState({ personDob: date }, util.validatePersonDob)}
                                    dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                    onBlur={() => util.validatePersonDob()}
                                    isClearable
                                    strictParsing
                                 />
                                 <i aria-hidden="true" className="calendar outline icon"></i>
                              </div>
                              {this.state.error.personDob && <div className='errorText'>{this.state.error.personDob}</div>}
                           </Form.Field>
                           <Form.Field >
                              <div className='textNormal mt-1'><Icon className='colPrimary' name='info circle' /> Note: Look up and select address. If address not found click enter manually.</div>
                           </Form.Field>
                           <Form.Field required>
                              <label>Address Line 1</label>
                              <TextArea readOnly={this.state.selectedMapObj} rows={1} name='personAddressLine1' placeholder="Address Line 1  (max 2000 chars)" value={this.state.personAddressLine1} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonAddressLine1()} fluid required />
                              {this.state.error.personAddressLine1 && <div className='errorText'>{this.state.error.personAddressLine1}</div>}
                           </Form.Field>
                           <Form.Field>
                              <label>Address Line 2</label>
                              <TextArea readOnly={this.state.selectedMapObj} rows={1} name='personAddressLine2' placeholder="Address Line 2  (max 2000 chars)" value={this.state.personAddressLine2} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonAddressLine2()} />
                              {this.state.error.personAddressLine2 && <div className='errorText'>{this.state.error.personAddressLine2}</div>}
                           </Form.Field>
                           <Form.Field>
                              <label>Address Line 3</label>
                              <TextArea readOnly={this.state.selectedMapObj} rows={1} name='personAddressLine3' placeholder="Address Line 3  (max 2000 chars)" value={this.state.personAddressLine3} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonAddressLine3()} />
                              {this.state.error.personAddressLine3 && <div className='errorText'>{this.state.error.personAddressLine3}</div>}
                           </Form.Field>
                           <Form.Field required>
                              <label>City/Town</label>
                              <TextArea readOnly={this.state.selectedMapObj} rows={1} name='personCity' placeholder="City/Town (max 2000 chars)" value={this.state.personCity} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonCity()} fluid />
                              {this.state.error.personCity && <div className='errorText'>{this.state.error.personCity}</div>}
                           </Form.Field>
                           <Form.Group widths='equal'>
                              <Form.Field required>
                                 <label>Post Code</label>
                                 <Input readOnly={this.state.selectedMapObj} name='personPostCode' placeholder="Post Code (max 50 chars)" value={this.state.personPostCode} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonPostCode()} fluid />
                                 {this.state.error.personPostCode && <div className='errorText'>{this.state.error.personPostCode}</div>}
                              </Form.Field>
                              <Form.Field>
                                 <label>UPRN</label>
                                 <Input readOnly={this.state.selectedMapObj} name='personUprn' placeholder="UPRN (max 50 chars)" value={this.state.personUprn} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validatePersonUprn()} fluid />
                                 {this.state.error.personUprn && <div className='errorText'>{this.state.error.personUprn}</div>}
                              </Form.Field>
                           </Form.Group>
                        </Form>
                        <Grid>
                           <Grid.Column computer={16} className='mt-1' textAlign='right'>
                              {this.state.hasLookedUpAddress && <Button className='customBtn1Secondary' onClick={() => this.addressLookUpReset()}>Reset</Button>}
                              {/* <Button className='customBtn1Primary mr-0' onClick={(e) => this.addressLookUp(e)}>Look Up</Button> */}
                           </Grid.Column>
                        </Grid>
                     </Grid.Column>
                     <Grid.Column stretched>
                        <Segment className=''>
                           <div className='colPrimary textNormal textBold mb-5px'>Address Look Up <span className='colGrey60 textSmall textThin'>(In your own council area)</span></div>
                           <Form size='small'>
                              <Form.Group widths='equal'>
                                 <Form.Field>
                                    <Input name='lookupAddressText' placeholder="House No./ Street/ Town/ Postcode" value={this.state.lookupAddressText} onChange={(e) => this.handleTextChange(e)} />
                                 </Form.Field>
                                 <Form.Field width={8}>
                                    <Input name='lookupAddressUprn' placeholder="UPRN" value={this.state.lookupAddressUprn} onChange={(e) => this.handleTextChange(e)} />
                                 </Form.Field>
                                 <Form.Field width={2}>
                                    <Button icon='search' className='customBtn1Primary' onClick={(e) => this.addressLookUp(e)} />
                                 </Form.Field>
                              </Form.Group>
                           </Form>
                           <Segment className='mt-1 p-0' loading={this.state.isLookingUpAddress}>
                              {!this.state.isLookingUpAddress && <AddressLookUpMap
                                 data={this.state.addressLookUpList}
                                 height='292px' width='100%' center={[56.4907, -4.2026]}
                                 zoom={2} scrollWheelZoom={true}
                                 selectedPoint={this.state.selectedMapObj}
                              />}
                              <AddressLookUp
                                 selectedPoint={this.state.selectedMapObj}
                                 data={this.state.addressLookUpList}
                                 handleAddressSelect={this.handleAddressSelect}
                                 addressLookUpReset={this.addressLookUpReset}
                                 hasLookedUpAddress={this.state.hasLookedUpAddress}
                              />
                           </Segment>
                        </Segment>
                     </Grid.Column>
                  </Grid>
               </Segment>
               <Segment>
                  <Form size='small'>
                     <Header as='h4' className='colPrimary'>Details of the dog</Header>
                     <Grid>
                        <Grid.Column width={4}>
                           <BreedImage imageName={`${this.state.breedImgLocation}`} style={{ maxHeight: '320px' }} />
                           {/* <Image src={`/dogbreeds/${this.state.breedImgLocation}`} alt="demoImg" style={{ maxHeight: '320px' }} /> */}
                        </Grid.Column >
                        <Grid.Column width={12}>
                           <Form.Group widths='equal'>
                              <Form.Field fluid>
                                 <label>Dog Breed</label>
                                 <Select required
                                    fluid search selection label='' placeholder='Dog Breed'
                                    options={[{ key: 'Other', text: 'Other', value: 'Other' }, ...this.state.dogBreedOptions]} value={this.state.dogBreed}
                                    onChange={(e, { value }) => this.handleDogBreedChange(value)}
                                    onBlur={() => util.validateDogBreed()}
                                 />
                                 {this.state.error.dogBreed && <div className='errorText'>{this.state.error.dogBreed}</div>}
                              </Form.Field>

                              <Form.Field fluid>
                                 <Form.Input name='dogMicrochipCode' label="Microchip Code Number" placeholder="Microchip Code Number (max 25 chars)" value={this.state.dogMicrochipCode} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateDogMicrochipCode()} />
                                 {this.state.error.dogMicrochipCode && <div className='errorText'>{this.state.error.dogMicrochipCode}</div>}
                              </Form.Field>
                           </Form.Group>
                           <Form.Group widths='equal'>
                              <Form.Field fluid>
                                 <Form.Input name='dogName' label="Dog Name" placeholder="Dog Name (max 255 chars)" value={this.state.dogName} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateDogName()} required />
                                 {this.state.error.dogName && <div className='errorText'>{this.state.error.dogName}</div>}
                              </Form.Field>
                              <Form.Field fluid>
                                 <label>Dog Sex</label>
                                 <Select required fluid
                                    fluid search selection label='Dog Sex' placeholder='Dog Sex'
                                    options={this.state.dogSexOptions} value={this.state.dogSex}
                                    onChange={(e, { value }) => this.handleDropdownChange('dogSex', value)}
                                    onBlur={() => util.validateDogSex()}
                                 />
                                 {this.state.error.dogSex && <div className='errorText'>{this.state.error.dogSex}</div>}
                              </Form.Field>
                           </Form.Group>
                           <Form.Group widths='equal'>
                              <Form.Field>
                                 <Form.TextArea name='dCNReason' label="DCN Reason" placeholder="DCN Reason (max 2000 chars)" value={this.state.dCNReason} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateDCNReason()} fluid required />
                                 {this.state.error.dCNReason && <div className='errorText'>{this.state.error.dCNReason}</div>}
                              </Form.Field>
                              <Form.Field>
                                 <Form.TextArea name='dogIdentificationMark' label="Description of dog / Identification Mark" placeholder="Dog Description / Identification Mark (max 500 chars)" value={this.state.dogIdentificationMark} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateDogIdentificationMark()} fluid required />
                                 {this.state.error.dogIdentificationMark && <div className='errorText'>{this.state.error.dogIdentificationMark}</div>}
                              </Form.Field>
                           </Form.Group>
                        </Grid.Column>
                     </Grid >
                  </Form >
               </Segment >

               <Segment>
                  <Grid columns='equal'>
                     <Grid.Column verticalAlign='middle'>
                        <Header as='h4' className='colPrimary'>Detailed steps to be taken (Up to 10 Action Items)</Header>
                     </Grid.Column>
                     <Grid.Column textAlign='right'>
                        <Button size='small' className='customBtn1Primary mr-0' onClick={() => this.addStep()}>Add Step</Button>
                     </Grid.Column>
                  </Grid>

                  <Form size='small'>
                     {
                        this.state.dcnActionItems && this.state.dcnActionItems.map((item, key) => {
                           return <>
                              <Form.Group >
                                 <Form.Field width={15} >
                                    <TextArea rows={2} label={`Action Item ${key + 1}`} placeholder={`Action Item ${key + 1}  (max 1000 chars)`} value={item.actionName} name='actionName' onChange={(e) => this.handleActionItemTextChange(e, key)} fluid onBlur={(e) => { e.preventDefault(); util.validateDcnActionItems() }} required />
                                 </Form.Field>

                                 <Form.Field width={1} >
                                    <Icon className='cursorPointer' circular color='red' name='trash' onClick={() => this.removeStep(key)} style={{ position: 'relative', top: '14px' }} />
                                 </Form.Field>
                              </Form.Group>
                           </>
                        })
                     }
                     {this.state.error.dcnActionItems && <div className='errorText'>{this.state.error.dcnActionItems}</div>}
                  </Form>
               </Segment>

               <Segment>
                  <Form size='small'>
                     <Header as='h4' className='colPrimary'>Details of the issuer</Header>
                     <Form.Select required
                        fluid search selection label='Issuer' placeholder='Issuer'
                        options={this.state.issuerNameOptions} value={this.state.issuer}
                        onChange={(e, { value }) => this.handleIssuerChange(value)}
                        onBlur={() => util.validateIssuer()}
                     />
                     {this.state.error.issuer && <div className='errorText'>{this.state.error.issuer}</div>}

                     <Form.Group widths='equal'>
                        <Form.Field>
                           <Form.Input readOnly={this.state.issuer !== 'Other'} name='issuerForename' label="Forename" placeholder="Forename (max 50 chars)" value={this.state.issuerForename} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerForeName()} fluid required />
                           {this.state.error.issuerForename && <div className='errorText'>{this.state.error.issuerForename}</div>}
                        </Form.Field>
                        <Form.Field>
                           <Form.Input readOnly={this.state.issuer !== 'Other'} name='issuerSurname' label="Surname" placeholder="Surname (max 50 chars)" value={this.state.issuerSurname} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerLastName()} fluid required />
                           {this.state.error.issuerSurname && <div className='errorText'>{this.state.error.issuerSurname}</div>}
                        </Form.Field>
                        <Form.Field>
                           <Form.Input readOnly={this.state.issuer !== 'Other'} name='issuerContactNumber' label="Contact Number" placeholder="Contact Number (max 50 chars)" value={this.state.issuerContactNumber} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerContactNumber()} fluid required />
                           {this.state.error.issuerContactNumber && <div className='errorText'>{this.state.error.issuerContactNumber}</div>}
                        </Form.Field>
                     </Form.Group>

                     {/* <Form.Field required><label>Issuer Office Address</label></Form.Field> */}
                     <Form.Group widths='equal'>
                        <Form.Field>
                           <Form.TextArea rows={1} name='issuerAddressLine1' label='Address Line 1' placeholder="Address Line 1 (max 2000 chars)" value={this.state.issuerAddressLine1} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerAddressLine1()} fluid required />
                           {this.state.error.issuerAddressLine1 && <div className='errorText'>{this.state.error.issuerAddressLine1}</div>}
                        </Form.Field>
                        <Form.Field>
                           <Form.TextArea rows={1} name='issuerAddressLine2' label='Address Line 2' placeholder="Address Line 2 (max 2000 chars)" value={this.state.issuerAddressLine2} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerAddressLine2()} />
                           {this.state.error.issuerAddressLine2 && <div className='errorText'>{this.state.error.issuerAddressLine2}</div>}
                        </Form.Field>
                     </Form.Group>
                     <Form.Group widths='equal'>
                        <Form.Field>
                           <Form.TextArea rows={1} name='issuerCity' label='City/Town' placeholder="City/Town (max 2000 chars)" value={this.state.issuerCity} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerCity()} fluid required />
                           {this.state.error.issuerCity && <div className='errorText'>{this.state.error.issuerCity}</div>}
                        </Form.Field>
                        <Form.Field>
                           <Form.Input name='issuerPostCode' label='Post Code' placeholder="Post Code (max 50 chars)" value={this.state.issuerPostCode} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerPostCode()} fluid required />
                           {this.state.error.issuerPostCode && <div className='errorText'>{this.state.error.issuerPostCode}</div>}
                        </Form.Field>
                     </Form.Group>
                     {/* <Form.Field >
                        <Form.Input width={8} name='issuerUprn' placeholder="UPRN" value={this.state.issuerUprn} onChange={(e) => this.handleTextChange(e)} onBlur={() => util.validateIssuerUprn()} fluid required />
                        {this.state.error.issuerUprn && <div className='errorText'>{this.state.error.issuerUprn}</div>}
                     </Form.Field> */}
                  </Form>
               </Segment>

               <Segment basic className='p-0' loading={this.state.isDeletingFile}>
                  <Grid columns='equal'>
                     <Grid.Column stretched>
                        {this.state.tempDcnFileName && <Message
                           color='blue' className='mb-0'
                           header={`Please Upload DCN - ${this.state.tempDcnFileName}`}
                        />}
                        <FileUpload
                           title='Upload Scanned DCN PDF' type='dcn'
                           referenceCode={this.state.dcnId}
                           viewmode={false}
                           bpid={utility.fetchUserBpid()} setDocListCount={() => { }}
                           onFileUploadSuccess={() => { this.setState({ tempDcnFileName: '' }); this.onFileUploadSuccess() }}
                        />
                     </Grid.Column>
                     <Grid.Column stretched width={8}>
                        {this.state.dcnFileName ?
                           <Segment textAlign='center'>
                              <Header as='h4' icon>
                                 <Icon className='colPrimary' name='file pdf' /> {this.state.dcnFileName}
                              </Header>
                              <div>
                                 <Icon name='download' className='customLink px-1' onClick={() => this.downloadFile(this.state.dcnId, this.state.dcnFileName)} />
                                 <Icon name='trash' color='red' className='cursorPointer px-1' onClick={() => this.setState({ deleteFileConfirmation: { dcnId: this.state.dcnId, fileName: this.state.dcnFileName, type: 'dcn' } })} />
                                 {/* <Icon name='trash' color='red' className='cursorPointer px-1' onClick={() => this.deleteFile(this.state.dcnId, this.state.dcnFileName, 'dcn')} /> */}
                                 <Icon name='' className='customLink px-1' onClick={() => this.setState({ pdfPreviewSelectedFileName: this.state.dcnFileName })}>Preview</Icon>
                              </div>
                           </Segment>
                           :
                           <Segment placeholder style={{ minHeight: 'inherit' }}>
                              <Header color='grey' as='h4' icon ><Icon name='pdf file' /> DCN has not been uploaded.
                              {this.state.error.dcnFileName && <div className='errorText'>{this.state.error.dcnFileName}</div>}
                              </Header>
                           </Segment>
                        }
                     </Grid.Column>
                  </Grid>
                  <Grid columns='equal'>
                     <Grid.Column stretched>
                        {this.state.tempSonFileName && <Message
                           color='blue' className='mb-0'
                           header={`Please Upload SON - ${this.state.tempSonFileName}`}
                        />}
                        <FileUpload
                           title='Upload Service of Notice' type='son'
                           referenceCode={this.state.dcnId}
                           viewmode={false}
                           bpid={utility.fetchUserBpid()} setDocListCount={() => { }}
                           onFileUploadSuccess={() => { this.setState({ tempSonFileName: '' }); this.onFileUploadSuccess() }}
                        />
                     </Grid.Column>
                     <Grid.Column stretched width={8}>
                        {this.state.sonFileName ?
                           <Segment textAlign='center'>
                              <Header as='h4' icon>
                                 <Icon className='colPrimary' name='file pdf' /> {this.state.sonFileName}
                              </Header>
                              <div>
                                 <Icon name='download' className='customLink px-1' onClick={() => this.downloadFile(this.state.dcnId, this.state.sonFileName)} />
                                 <Icon name='trash' color='red' className='cursorPointer px-1' onClick={() => this.setState({ deleteFileConfirmation: { dcnId: this.state.dcnId, fileName: this.state.sonFileName, type: 'son' } })} />
                                 {/* <Icon name='trash' color='red' className='cursorPointer px-1' onClick={() => this.deleteFile(this.state.dcnId, this.state.sonFileName, 'son')} /> */}
                                 <Icon name='' className='customLink px-1' onClick={() => this.setState({ pdfPreviewSelectedFileName: this.state.sonFileName })}>Preview</Icon>
                              </div>
                           </Segment>
                           :
                           <Segment placeholder style={{ minHeight: 'inherit' }}>
                              <Header color='grey' as='h4' icon ><Icon name='pdf file' />Service of Notice has not been uploaded.
                              {this.state.error.sonFileName && <div className='errorText'>{this.state.error.sonFileName}</div>}
                              </Header>
                           </Segment>
                        }
                     </Grid.Column>
                  </Grid>
               </Segment>

               {/* PDF Preview */}
               {this.state.pdfPreviewSelectedFileName &&
                  <Segment>
                     <Header as='h3' className='colPrimary' dividing>
                        {this.state.pdfPreviewSelectedFileName}
                     </Header>
                     <Icon size='large' style={{ position: 'absolute', right: '6px', top: '12px', zIndex: '1' }} link name='close' onClick={() => this.setState({ pdfPreviewSelectedFileName: false })} />
                     {/* <PdfPreview url={window.location.protocol + '//' + window.location.host + "/scannedpdf/" + this.state.bpid + '/' + this.state.pdfPreviewSelectedFileName} /> */}
                     <PdfPreview
                        refId={this.state.dcnId}
                        fileName={this.state.pdfPreviewSelectedFileName}
                     />
                  </Segment>
               }

               <Grid>
                  <Grid.Column computer={16} textAlign='right'>
                     <Button className='customBtn1Secondary mr-1' onClick={() => this.reserForm()}>Reset</Button>
                     <Button className='customBtn1Primary mr-1' loading={this.state.isSubmitting} onClick={() => util.createDraft()}>Save as Draft</Button>
                     {!formMode && <Button className='customBtn1Primary mr-0' loading={this.state.isSubmitting} onClick={() => util.publish()} disabled={this.state.disableSubmit}>Create DCN</Button>}
                     {formMode === 'Draft' && <Button className='customBtn1Primary mr-0' loading={this.state.isSubmitting} onClick={() => util.publish()} disabled={this.state.disableSubmit}>Create DCN</Button>}
                     {formMode === 'Variation' && <Button className='customBtn1Primary mr-0' loading={this.state.isSubmitting} onClick={() => util.publish()}>Create Variation</Button>}
                  </Grid.Column>
               </Grid>
            </Segment >

            {/* Delete Draft Modal */}
            <Modal open={this.state.deleteDraftModal} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig colPrimary textBold mb-1'></div>
                  <div className='textBig textBold '>Are you sure you want to delete this draft ?</div>
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ deleteDraftModal: false })}>Cancel</Button>
                  <Button className='customBtn1Primary' onClick={() => this.deleteDraftDcn()} loading={this.state.isDeletingDraft}>Yes</Button>
               </Modal.Actions>
            </Modal>
            {/* Delete All Draft Modal */}
            <Modal open={this.state.deleteAllDraftModal} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig colPrimary textBold mb-1'></div>
                  <div className='textBig textBold '>Are you sure you want to delete all the drafts ?</div>
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ deleteAllDraftModal: false })}>Cancel</Button>
                  <Button className='customBtn1Primary' onClick={() => this.deleteAllDraftDcn()} loading={this.state.isDeletingAllDraft}>Yes</Button>
               </Modal.Actions>
            </Modal>

            {/* Delete File Modal */}
            <Modal open={this.state.deleteFileConfirmation} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig colPrimary textBold mb-1'></div>
                  <div className='textBig textBold '>Are you sure you want to delete this file ?</div>
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ deleteFileConfirmation: false })}>Cancel</Button>
                  <Button className='customBtn1Primary' onClick={() => this.deleteFile()} loading={this.state.isDeletingFile}>Yes</Button>
               </Modal.Actions>
            </Modal>


         </>
      )
   }
}

export default withTranslation()(withRouter(CreateDcn))
