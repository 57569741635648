import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { withTranslation } from "react-i18next"
import { Button, Input, Icon, Grid, Search, Breadcrumb, Modal, Header, Step, Segment } from 'semantic-ui-react'
import FileUpload from '../../commons/fileupload/fileUpload.jsx'
import utility from '../../commons/utility.js'
import ajax from '../../commons/agent.js'
import PageHeader from '../PageHeader.jsx'

class UploadDcn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorResponse: [],
            loader: false,
            selectedFile: [],
            step: 1,
            dcnId: null,
        };

        this.onChangeFileHandler = this.onChangeFileHandler.bind(this);
    }

    componentDidMount() {
        this.props.fetchOrganizationCategory(utility.fetchUserBpid()) !== 'Local Authority' && this.props.history.push('/')
    }

    onChangeFileHandler(e) {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadDcnExcel(e) {
        self = this;

        if (self.state.selectedFile != null) {
            //utility.enterLoading(self);
            let formData = new FormData();
            formData.append("file", self.state.selectedFile);

            ajax.DCNService.uploadDcn(formData).end((err, response) => {
                //utility.enterUnLoading(self);
                let errJson = utility.handleErrors(err);
                if (!err && response) {
                    let msg = [];

                    if (response.status == "200") {
                        self.setState({ uploaded: true, selectedFile: [], step: 2, dcnId: response.text });
                    } else {
                        msg.push(response.text);
                        utility.loadDisplayMessage(self, "info", "Information.", msg);
                    }
                } else {
                    utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
                }
            })
        }
    }


    render() {
        return (
            <>
                <PageHeader />
                {utility.ShowLoadingV2(this)}
                {this.state.errorResponse.errFlag === 'Y' &&
                    <Grid className="mx-0 px-0 pt-1">
                        <Grid.Column width={16} className="m-1 p-0">
                            <ErrorMessage showServerError={this.state.errorResponse} />
                        </Grid.Column>
                    </Grid>
                }
                { true &&
                    <div className='px-0 min80vh'>

                        <Step.Group stackable='mobile' className='m-1'>
                            <Step active={this.state.step === 1} completed={this.state.step > 1}>
                                <Icon name='upload' />
                                <Step.Content>
                                    <Step.Title>UPLOAD A DCN</Step.Title>
                                    <Step.Description>Excel Only</Step.Description>
                                </Step.Content>
                            </Step>
                            <Step active={this.state.step === 2} disabled={this.state.step !== 2}>
                                <Icon name='upload' />
                                <Step.Content>
                                    <Step.Title>UPLOAD SCANNED DCN</Step.Title>
                                    <Step.Description>PDF Only</Step.Description>
                                </Step.Content>
                            </Step>
                        </Step.Group>


                        {this.state.step === 1 &&
                            <Grid columns='equal' className="mx-0 px-0">
                                <Grid.Column mobile={16} tablet={16} computer={16} className="m-1 p-2 bgWhite border-1 bgShadow" >
                                    {/* <Header as='h2'>
                                        <Icon name='file excel' />
                                        <Header.Content>UPLOAD A DCN</Header.Content>
                                    </Header> */}
                                    {/* <Segment> */}

                                    <input type="file" name="file" className="form-control" onChange={this.onChangeFileHandler} />
                                    <Button className='customBtn1Primary' size='tiny' onClick={this.uploadDcnExcel.bind(this)} > UPLOAD </Button>
                                    {/* </Segment> */}

                                </Grid.Column>
                            </Grid>
                        }
                        {this.state.step === 2 &&
                            <Grid columns='equal' className="mx-0 px-0">
                                <Grid.Column mobile={16} tablet={16} computer={16} className="m-1 p-2 bgWhite border-1 bgShadow" >
                                    {/* <Header as='h2'>
                                        <Icon name='file pdf' />
                                        <Header.Content>UPLOAD A DCN</Header.Content>
                                    </Header> */}
                                    {/* <FileUpload referenceCode={this.state.referenceCode} viewmode={this.state.viewmode} bpid={this.state.formbpid} dataProductCode={this.state.dataProductCode} setDocListCount={this.setDocListCount.bind(this)} /> */}
                                    <FileUpload referenceCode={this.state.dcnId} viewmode={this.state.dcnId ? false : true} bpid={1} dataProductCode={null} setDocListCount={() => { }} />

                                </Grid.Column>
                            </Grid>
                        }


                    </div>
                }
            </>
        );
    }
}
export default withTranslation()(withRouter(UploadDcn))
