import moment from "moment";
import ajax from '../../../commons/agent.js'
import utility from '../../../commons/utility.js'
import { message as floatNoti } from 'antd'

let context
let t
const initialize = (self, translate) => {
  context = self
  t = translate
  floatNoti.config({
    top: 40,
    duration: 1,
    maxCount: 3,
  })
}

const populateVo = () => {
  // let dcnActionItems = [...context.state.dcnActionItems]
  // dcnActionItems = dcnActionItems.map(item => {
  //   return (
  //     {
  //       ...item,
  //       dueDate: item.dueDate ? moment(item.dueDate).format("YYYY-MM-DD HH:mm") : null
  //     }
  //   )
  // })


  let Vo = {
    dcnId: context.state.dcnId,
    parentDcnId: context.state.parentDcnId,
    lastDcnId: context.state.lastDcnId,
    dcnStatus: context.state.dcnStatus,
    bpid: context.state.bpid,
    
    dcnReferenceNo: context.state.referenceNo,
    dcnEffectiveDate: context.state.dateServed ? moment(new Date(context.state.dateServed).setHours(0, 0, 0, 0)).format("YYYY-MM-DD HH:mm") : null,
    effectiveDate: context.state.effectiveDate,

    dogMicrochipCode: context.state.dogMicrochipCode,
    dogBreed: context.state.dogBreed,
    dogName: context.state.dogName,
    dogSex: context.state.dogSex,
    dcnReason: context.state.dCNReason,
    dogIdentificationMark: context.state.dogIdentificationMark,
    dogImgLocation: context.state.breedImgLocation,     

    issuerUserId: context.state.issuer === 'Other' ? null : context.state.issuer,
    localCouncilName: context.state.myOrg.organizationName,
    issuerForename: context.state.issuerForename,
    issuerSurname: context.state.issuerSurname,
    issuerAddressLine1: context.state.issuerAddressLine1,
    issuerAddressLine2: context.state.issuerAddressLine2,
    issuerCity: context.state.issuerCity,
    issuerPostCode: context.state.issuerPostCode,
    issuerContactNo: context.state.issuerContactNumber,

    ppForename: context.state.personForename,
    ppSurname: context.state.personSurname,
    ppDob: context.state.personDob ? moment(context.state.personDob).format("YYYY-MM-DD HH:mm") : null,
    ppAddressLine1: context.state.personAddressLine1,
    ppAddressLine2: context.state.personAddressLine2,
    ppAddressLine3: context.state.personAddressLine3,
    ppCity: context.state.personCity,
    ppPostCode: context.state.personPostCode,
    ppUprn: context.state.personUprn,    
    xCoordinate: context.state.xCoordinate,
    yCoordinate: context.state.yCoordinate,
    dcnActionItemsVo: context.state.dcnActionItems,

    dcnFileName: context.state.dcnFileName,
    sonFileName: context.state.sonFileName,
    tempDcnFileName: context.state.tempDcnFileName,
    tempSonFileName: context.state.tempSonFileName,
  }
  return Vo;
};

const validateReferenceNo = () => {
  let error = ''
  const value = context.state.referenceNo
  if (!value) error = t('requiredValidationRule', { what: t('referenceNo') })
  else if (value.length > 255) error = "Length should be less than 255 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, referenceNo: error }) }))
  return error
}

const validatePersonForename = () => {
  let error = ''
  const value = context.state.personForename
  if (!value) error = t('requiredValidationRule', { what: t('personForename') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personForename: error }) }))
  return error
}

const validatePersonSurname = () => {
  let error = ''
  const value = context.state.personSurname
  if (!value) error = t('requiredValidationRule', { what: t('personSurname') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personSurname: error }) }))
  return error
}

const validatePersonDob = () => {
  let error = ''
  const value = context.state.personDob
  // if (!value) error = 'Date is required in DD/MM/YYYY format'
  // if (!value) error = t('requiredValidationRule', { what: t('personDob') })


  context.setState(prevState => ({ error: ({ ...prevState.error, personDob: error }) }))
  return error
}

const validatePersonAddressLine1 = () => {
  let error = ''
  const value = context.state.personAddressLine1
  if (!value) error = t('requiredValidationRule', { what: t('personAddressLine1') })
  else if (value.length > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personAddressLine1: error }) }))
  return error
}

const validatePersonAddressLine2 = () => {
  let error = ''
  const value = context.state.personAddressLine2
  if (value && value.length  > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personAddressLine2: error }) }))
  return error
}
const validatePersonAddressLine3 = () => {
  let error = ''
  const value = context.state.personAddressLine3
  if (value && value.length  > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personAddressLine3: error }) }))
  return error
}

const validatePersonCity = () => {
  let error = ''
  const value = context.state.personCity
  if (!value) error = t('requiredValidationRule', { what: t('personCity') })
  else if (value.length > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personCity: error }) }))
  return error
}

const validatePersonPostCode = () => {
  let error = ''
  const value = context.state.personPostCode
  if (!value) error = t('requiredValidationRule', { what: t('personPostCode') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, personPostCode: error }) }))
  return error
}

const validatePersonUprn = () => {
  let error = ''
  const value = context.state.personUprn
  if (value && value.length  > 50) error = "Length should be less than 50 characters"


  context.setState(prevState => ({ error: ({ ...prevState.error, personUprn: error }) }))
  return error
}

const validateDogName = () => {
  let error = ''
  const value = context.state.dogName
  if (!value) error = t('requiredValidationRule', { what: t('dogName') })
  else if (value.length > 255) error = "Length should be less than 255 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, dogName: error }) }))
  return error
}

const validateDogMicrochipCode = () => {
  let error = ''
  const value = context.state.dogMicrochipCode
  if (value && value.length  > 25) error = "Length should be less than 25 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, dogMicrochipCode: error }) }))
  return error
}

const validateDogBreed = () => {
  let error = ''
  const value = context.state.dogBreed
  if (!value) error = t('requiredValidationRule', { what: t('dogBreed') })

  context.setState(prevState => ({ error: ({ ...prevState.error, dogBreed: error }) }))
  return error
}


const validateDogSex = () => {
  let error = ''
  const value = context.state.dogSex
  if (!value) error = t('requiredValidationRule', { what: t('dogSex') })

  context.setState(prevState => ({ error: ({ ...prevState.error, dogSex: error }) }))
  return error
}

const validateDCNReason = () => {
  let error = ''
  const value = context.state.dCNReason
  if (!value) error = t('requiredValidationRule', { what: t('dCNReason') })
  else if (value.length > 2000) error = "Length should be less than 2000 characters"


  context.setState(prevState => ({ error: ({ ...prevState.error, dCNReason: error }) }))
  return error
}

const validateDogIdentificationMark = () => {
  let error = ''
  const value = context.state.dogIdentificationMark
  if (!value) error = t('requiredValidationRule', { what: t('dogIdentificationMark') })
  else if (value.length > 500) error = "Length should be less than 500 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, dogIdentificationMark: error }) }))
  return error
}

const validateDcnActionItems = () => {
  let error = ''
  const value = context.state.dcnActionItems
  if (!value.length > 0) error = t('requiredValidationRule', { what: t('dcnActionItems') })
  value.length > 0 && value.map((item) => {
    !item.actionName ?
      error = t('requiredValidationRule', { what: t('dcnActionItems') })
      :
      item.actionName.length > 1000
        ? error = 'Length should be less than 1000 characters'
        :
        error = ''
  })


  context.setState(prevState => ({ error: ({ ...prevState.error, dcnActionItems: error }) }))
  return error
}

const validateIssuer = () => {
  let error = ''
  const value = context.state.issuer
  if (!value) error = t('requiredValidationRule', { what: t('issuer') })

  context.setState(prevState => ({ error: ({ ...prevState.error, issuer: error }) }))
  return error
}

const validateIssuerForeName = () => {
  let error = ''
  const value = context.state.issuerForename
  if (!value) error = t('requiredValidationRule', { what: t('issuerForename') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerForename: error }) }))
  return error
}

const validateIssuerLastName = () => {
  let error = ''
  const value = context.state.issuerSurname
  if (!value) error = t('requiredValidationRule', { what: t('issuerSurname') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerSurname: error }) }))
  return error
}

const validateIssuerContactNumber = () => {
  let error = ''
  const value = context.state.issuerContactNumber
  if (!value) error = t('requiredValidationRule', { what: t('issuerContactNumber') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerContactNumber: error }) }))
  return error
}

const validateDateServed = () => {
  let error = ''
  const value = context.state.dateServed
  if (!value) error = 'Date is required in DD/MM/YYYY format'

  context.setState(prevState => ({ error: ({ ...prevState.error, dateServed: error }) }))
  return error
}

const validateIssuerAddressLine1 = () => {
  let error = ''
  const value = context.state.issuerAddressLine1
  if (!value) error = t('requiredValidationRule', { what: t('issuerAddressLine1') })
  else if (value.length > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerAddressLine1: error }) }))
  return error
}

const validateIssuerAddressLine2 = () => {
  let error = ''
  const value = context.state.issuerAddressLine2
  // if (!value) error = t('requiredValidationRule', { what: t('issuerAddressLine2') })
  if (value && value.length > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerAddressLine2: error }) }))
  return error
}

const validateIssuerCity = () => {
  let error = ''
  const value = context.state.issuerCity
  if (!value) error = t('requiredValidationRule', { what: t('issuerCity') })
  else if (value.length > 2000) error = "Length should be less than 2000 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerCity: error }) }))
  return error
}

const validateIssuerPostCode = () => {
  let error = ''
  const value = context.state.issuerPostCode
  if (!value) error = t('requiredValidationRule', { what: t('issuerPostCode') })
  else if (value.length > 50) error = "Length should be less than 50 characters"

  context.setState(prevState => ({ error: ({ ...prevState.error, issuerPostCode: error }) }))
  return error
}

const validatDcnUpload = () => {
  let error = ''
  const value = context.state.dcnFileName
  if (!value) error = 'DCN document is required'


  context.setState(prevState => ({ error: ({ ...prevState.error, dcnFileName: error }) }))
  return error
}
const validatSonUpload = () => {
  let error = ''
  const value = context.state.sonFileName
  // if (!value) error = 'Service of Notice document is required'


  context.setState(prevState => ({ error: ({ ...prevState.error, sonFileName: error }) }))
  return error
}
// const validateIssuerUprn = () => {
//   let error = ''
//   const value = context.state.issuerUprn
//   if (value.length > 50) error = "Length should be less than 50 characters"

//   context.setState(prevState => ({ error: ({ ...prevState.error, issuerUprn: error }) }))
//   return error
// }

const validateDraft = (Vo) => {
  validateReferenceNo()

  return (
    validateReferenceNo()
  )
}
const validateAll = (Vo) => {
  validateReferenceNo()
  validatePersonForename()
  validatePersonSurname()
  validatePersonDob()
  validatePersonAddressLine1()
  validatePersonAddressLine2()
  validatePersonAddressLine3()
  validatePersonCity()
  validatePersonPostCode()
  validatePersonUprn()
  validateDogName()
  validateDogBreed()
  validateDogSex()
  validateDogMicrochipCode()
  validateDCNReason()
  validateDogIdentificationMark()
  validateIssuer()
  validateIssuerForeName()
  validateIssuerLastName()
  validateIssuerContactNumber()
  validateDateServed()
  validateIssuerAddressLine1()
  validateIssuerAddressLine2()
  // validateIssuerAddressLine3()
  validateIssuerCity()
  validateIssuerPostCode()
  // validateIssuerUprn()
  validateDcnActionItems()

  validatDcnUpload()
  validatSonUpload()


  return (
    validateReferenceNo() || validatePersonForename() || validatePersonSurname() || validatePersonDob() || validatePersonAddressLine1() || validatePersonAddressLine2() || validatePersonAddressLine3() || validatePersonCity() || validatePersonPostCode() || validatePersonUprn() || validateDogName() ||
    validateDogBreed() || validateDogSex() || validateDCNReason() || validateDogIdentificationMark() || validateIssuer() || validateIssuerForeName() || validateIssuerLastName() || validateIssuerContactNumber() || validateDateServed() ||
    validateIssuerAddressLine1() || validateIssuerAddressLine2() || validateIssuerCity() || validateIssuerPostCode() || validateDcnActionItems() ||
    validatDcnUpload() || validatSonUpload() || validateDogMicrochipCode()
  )
}

const createDraft = () => {
  let Vo = populateVo();

  if (validateDraft(Vo)) {
    utility.loadDisplayMessage(context, "error", "Invalid Input", ['Fill the required form fields with valid data.'])
  }
  else {
    // console.log(Vo);    
    const key = 'updatable';
    context.setState({ isSubmitting: true })
    floatNoti.loading({ content: 'Saving draft', key })
    ajax.DCNService.createDcnDraft(Vo).end((err, response) => {
      context.setState({ isSubmitting: false })
      utility.handleErrors(err);
      if (!err && response) {
        context.fetchDraftDcns()
        // context.setState({ dcnId: response.body.dcnId })
        context.initializeTheForm(response.body)
        // context.props.history.push(`/dashboard/dogControlNotices/dcnsdetailpage/${response.body.dcnId}`);
        floatNoti.success({ content: 'Draft saved', key })
      }
      else {
        utility.loadDisplayMessage(context, "error", "Something went wrong.", [])
      }
    });
  }
};
const publish = () => {
  let Vo = populateVo();

  if (validateAll(Vo)) {
    utility.loadDisplayMessage(context, "error", "Invalid Input", ['Fill the required form fields with valid data.'])
  }
  else {
    // console.log(Vo);
    clearInterval(context.state.autoSaveIntervalId)
    context.setState({ isSubmitting: true, disableSubmit: true })
    ajax.DCNService.createDCN(Vo).end((err, response) => {
      let autoSaveIntervalId = setInterval(context.autoSave, 60000)
      context.setState({ autoSaveIntervalId: autoSaveIntervalId })

      context.setState({ isSubmitting: false })
      utility.handleErrors(err);
      if (!err && response) {
        context.props.history.push('/dashboard/dogControlNotices');
      }
      else {
        utility.loadDisplayMessage(context, "error", "Something went wrong.", [])
      }
    });
  }
};

export default {
  initialize,
  publish,
  createDraft,
  validateReferenceNo,
  validatePersonForename,
  validatePersonSurname,
  validatePersonDob,
  validatePersonAddressLine1,
  validatePersonAddressLine2,
  validatePersonAddressLine3,
  validatePersonCity,
  validatePersonPostCode,
  validatePersonUprn,
  validateDogName,
  validateDogBreed,
  validateDogSex,
  validateDCNReason,
  validateDogMicrochipCode,
  validateDogIdentificationMark,
  validateIssuer,
  validateIssuerForeName,
  validateIssuerLastName,
  validateIssuerContactNumber,
  validateDateServed,
  validateIssuerAddressLine1,
  validateIssuerAddressLine2,
  validateIssuerCity,
  validateIssuerPostCode,
  // validateIssuerUprn,
  validateDcnActionItems,
  validateDraft
}
